<template>
  <div>
    <b-form>
      <b-form-group class="form-element">
        <b-row>
          <b-col sm="4">
            <h3 class="flex-0 mr-4 font-style-2">{{ pt_props.serviceTime }}</h3>
            <hr style="margin: 2px 0; border-color: #373737" />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="4">
            <b-button
              variant="tertiary"
              class="flex-1 d-block w-100-sd btn-pl-xsm btn-text-sm"
              @click="handleRemoveButtonClick"
              >{{ i18n['presentation-time'].tcRemoveThisTime }}</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="4">
            <div class="form_label">{{ i18n['presentation-time'].tcMediaType }}</div>
            <b-form-select
              v-model="pt_props.mediaTypeKey"
              :options="presentationMediaTypesTranslated"
              class="form-control flex-1 g-select"
            >
              <template slot="first">
                <option :value="null" disabled>{{ i18n['presentation-time'].tcSelectMediaType }}</option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <div class="form_label">{{ i18n['presentation-time'].tcSpeaker }}</div>
            <b-form-select
              v-model="pt_props.speakerKey"
              :options="[...pt_props.speakerList, ...pt_props.searchedSpeaker]"
              class="form-control flex-1 g-select w-auto mr-2"
            >
              <template slot="first">
                <option :value="null" disabled>{{ i18n['presentation-time'].tcSelectASpeaker }}</option>
              </template>
            </b-form-select>
            <b-button @click="handleSpeakerFindClick(pt_props)" variant="primary" class="flex-0 mr-0 btn-pl-xsm">{{
              i18n['presentation-time'].tcFind
            }}</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="4">
            <div class="form_label">{{ i18n['presentation-time'].tcMinutes }}</div>
            <b-form-input
              v-model="pt_props.speakerMinutes"
              min="1"
              max="60"
              onKeyPress="if(this.value.length==2) return false;"
              type="number"
              @change="handleChange($event)"
              class="form-control number-select g-select mr-3 ml-0"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
    <hr />
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'presentation-time',
  mixins: [translationMixin],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'presentation-time': {
            tcFind: 'Find',
            tcMediaType: 'Media Type',
            tcMinutes: 'Minutes',
            tcRemoveThisTime: 'Remove this Time',
            tcSelectASpeaker: 'Select a Speaker',
            tcSelectMediaType: 'Select media type',
            tcSpeaker: 'Speaker',
          },
        }
      },
    },
    pt_props: {
      type: Object,
      default: {
        serviceTime: '',
        serviceTimeKey: null,
        serviceType: [],
        speakerList: [],
        attireList: [],
        removeKey: null,
        searchedSpeaker: [],
        speakerKey: null,
        attire: null,
        mediaTypeKey: null,
        speakerMinutes: null,
      },
    },
  },
  data() {
    return {
      presentationMediaTypesTranslated: {},
      translations: {},
    }
  },
  async created() {
    try {
      await Promise.all([
        this.setLoadingStatus(true),
        this.loadPresentationMediaTypes(),
        this.getComponentTranslations('common.presentation-types'),
      ]).then((results) => {
        this.stripReadableText(results[2])
        this.translateDropdownOptions([{ node: 'presentation-types', store: 'presentationMediaTypes' }])
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      loadPresentationMediaTypes: 'churchMinistry/loadPresentationMediaTypes',
      setLoadingStatus: 'menu/setLoadingStatus'
    }),
    handleRemoveButtonClick() {
      this.$emit('remove_presentation_time', this.pt_props.removeKey)
    },
    async handleChange(evt) {
      if (this.pt_props.speakerMinutes > 60) {
        this.pt_props.speakerMinutes = 60
      }
    },
    handleSpeakerFindClick(evt) {
      this.$emit('speakerFindClicked', evt)
    },
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
      presentationMediaTypes: 'churchMinistry/presentation_media_types',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.form_label {
  font-size: 14px !important;
  font-weight: 600;
}
.number-select {
  max-width: 135px;
  margin-left: 15px;
  text-align: center;
}
.col-sm-4 {
  margin-bottom: 20px;
}
</style>
